import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      className='prefix__animated'
      id='prefix__freepik_stories-monster-404-error'
      viewBox='0 0 500 500'
      {...props}
    >
      <style>
        {
          '@keyframes lightSpeedRight{60%{transform:skewX(10deg);opacity:1}80%{transform:skewX(-2deg)}}@keyframes slideRight{0%{opacity:0;transform:translateX(30px)}to{opacity:1;transform:translateX(0)}}@keyframes lightSpeedLeft{60%{transform:skewX(-10deg);opacity:1}80%{transform:skewX(2deg)}}@keyframes slideLeft{0%{opacity:0;transform:translateX(-30px)}to{opacity:1;transform:translateX(0)}}@keyframes zoomIn{0%{opacity:0;transform:scale(.5)}to{opacity:1;transform:scale(1)}}svg#prefix__freepik_stories-monster-404-error:not(.prefix__animated) .prefix__animable{opacity:0}'
        }
      </style>
      <g
        id='prefix__freepik--background-complete--inject-46'
        className='prefix__animable'
        style={{
          transformOrigin: '248.86px 261.844px',
          animation:
            '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) lightSpeedRight',
          animationDelay: '0s'
        }}
      >
        <path
          d='M467.74 191.66c.74-5.44-6.93-9.91-12.69-10.38-2.75-.22-5.61.2-8.21-.71-5.65-2-7.9-9.25-13.41-11.58-4.11-1.73-8.85-.19-12.84 1.82s-7.94 4.54-12.4 4.82c-3.88.25-8.09-1.19-11.48.71-2.53 1.41-3.85 4.32-6.18 6s-5.26 2-8.09 2.19-5.76.19-8.33 1.37-4.71 3.9-4.17 6.68z'
          style={{
            transformOrigin: '418.823px 180.41px'
          }}
          id='prefix__el3qpl2kz3iep'
          className='prefix__animable'
          fill='#f5f5f5'
        />
        <path
          d='M216.8 102.42c.59-4.28-5.45-7.8-10-8.17-2.16-.17-4.41.16-6.46-.55-4.44-1.55-6.21-7.29-10.54-9.12-3.24-1.36-7-.15-10.11 1.43s-6.24 3.57-9.75 3.8c-3 .19-6.37-.94-9 .55-2 1.11-3 3.41-4.86 4.76s-4.14 1.6-6.36 1.72-4.53.15-6.56 1.07-3.7 3.08-3.28 5.26z'
          style={{
            transformOrigin: '178.327px 93.5802px'
          }}
          id='prefix__elyrb0yjd929o'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M170.39 74.06c1-7.15-9.12-13-16.71-13.65-3.61-.29-7.37.26-10.8-.94-7.43-2.59-10.4-12.18-17.65-15.24-5.42-2.29-11.66-.25-16.91 2.39S97.87 52.59 92 53c-5.1.33-10.65-1.57-15.11.93-3.33 1.86-5.08 5.69-8.14 8s-6.92 2.69-10.64 2.89-7.58.24-11 1.79-6.19 5.14-5.49 8.8z'
          style={{
            transformOrigin: '105.985px 59.3236px'
          }}
          id='prefix__el81xjrnfsl5s'
          className='prefix__animable'
          fill='#f5f5f5'
        />
        <path
          d='M90.88 338.74s-7.5-2.43-11 14.87-4 26.86-4 26.86-1.16 3.75-3.92 2.28-7.38-6.54-10.79-4.45-3.62 6.82-1.14 12.92 7.44 19.68 7.88 20.91.23 3.21-1.81 2.51-14.93-8.94-18.48-2.58 6.17 22.22 7.2 24 .88 2.66.11 3.38-4.84-2.46-8.61-4.65-8.51-4.08-10.25-.24 1 17 19.16 34.6l8.94 7.6L75.29 473c23.4-9.54 30.88-20.71 30.7-24.92s-5.28-4.24-9.6-3.61-9.28 2.05-9.72 1.09-.24-1.85 1.37-3.1 16.55-12.33 15.64-19.55-16-4.39-18.11-4.51-1.59-2.05-.73-3 10.53-11.77 15.16-16.5 6.25-9.07 3.77-12.41-8.56-.24-11.67.09-2.82-3.58-2.82-3.58 3.1-9.05 6.31-26.42-4.86-17.89-4.86-17.89'
          style={{
            transformOrigin: '70.8084px 407.687px'
          }}
          id='prefix__eld389q3pwzvk'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M62.88 479.26c2.41-12.74 7.06-35.32 10.95-54.73s7.4-37 9.87-49.69c1.23-6.36 2.22-11.51 2.91-15.08.34-1.77.61-3.15.79-4.1.09-.46.17-.81.22-1.07s.08-.36.08-.36v.37c0 .24-.1.61-.18 1.07-.17 1-.41 2.34-.72 4.11-.64 3.57-1.59 8.73-2.8 15.11-2.4 12.74-5.87 30.32-9.76 49.72s-8.59 42-11 54.7'
          style={{
            transformOrigin: '75.29px 416.77px'
          }}
          id='prefix__el39nsa0in7xk'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M67.31 385.64a4.37 4.37 0 01.5.69c.3.46.72 1.12 1.22 2 1 1.66 2.36 4 3.79 6.6l3.65 6.68 1.12 2a3.85 3.85 0 01.38.76 4.1 4.1 0 01-.51-.69c-.3-.45-.71-1.12-1.21-2-1-1.68-2.31-4-3.75-6.63s-2.7-4.88-3.69-6.65l-1.13-2a3.41 3.41 0 01-.37-.76z'
          style={{
            transformOrigin: '72.64px 395.005px'
          }}
          id='prefix__el8j04ujx1lif'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M79.06 404.26c0-.09 4.64-3.06 10.49-6.65s10.62-6.43 10.67-6.34-4.64 3.06-10.48 6.65-10.63 6.43-10.68 6.34z'
          style={{
            transformOrigin: '89.6402px 397.765px'
          }}
          id='prefix__elhmmeih5ckvr'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M71.74 433.79a3.51 3.51 0 01.82-.27l2.27-.63c1.92-.52 4.56-1.26 7.45-2.19s5.48-1.83 7.34-2.52l2.22-.8a4.14 4.14 0 01.82-.26 3.39 3.39 0 01-.77.39c-.51.23-1.25.55-2.17.92a109.41 109.41 0 01-14.82 4.71c-1 .23-1.76.41-2.3.51a4.72 4.72 0 01-.86.14z'
          style={{
            transformOrigin: '82.2px 430.455px'
          }}
          id='prefix__elzg79lx379gs'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M57.4 419.29a2.92 2.92 0 01.62.51l1.59 1.48c1.33 1.27 3.16 3 5.15 5s3.79 3.76 5.08 5.08l1.51 1.56a3.25 3.25 0 01.52.62 4 4 0 01-.62-.51l-1.59-1.49c-1.33-1.27-3.16-3-5.15-5s-3.79-3.77-5.08-5.08l-1.51-1.57a3.44 3.44 0 01-.52-.6z'
          style={{
            transformOrigin: '64.635px 426.415px'
          }}
          id='prefix__el315v2z9qui'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M47.63 444.53a6.47 6.47 0 01.78.66l2.05 1.9c1.71 1.61 4.07 3.86 6.65 6.36s4.91 4.78 6.58 6.44l2 2a5.13 5.13 0 01.69.76 5 5 0 01-.78-.66c-.54-.49-1.22-1.13-2.05-1.9-1.72-1.61-4.07-3.86-6.65-6.36s-4.91-4.77-6.58-6.43l-2-2a4.89 4.89 0 01-.69-.77z'
          style={{
            transformOrigin: '57.005px 453.59px'
          }}
          id='prefix__elrxckdtpt8nm'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M66 462.07a8.72 8.72 0 011-.37l2.85-.89c2.41-.76 5.73-1.81 9.36-3.08s6.89-2.5 9.24-3.41l2.79-1.07a5.32 5.32 0 011-.35 6.87 6.87 0 01-1 .48c-.64.29-1.58.7-2.74 1.18-2.33 1-5.58 2.25-9.22 3.51s-7 2.29-9.41 3c-1.21.34-2.2.61-2.88.78a7.1 7.1 0 01-.99.22z'
          style={{
            transformOrigin: '79.12px 457.485px'
          }}
          id='prefix__el119suirzb2nn'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M297.8 409a15.14 15.14 0 0110.91 4.62 26.66 26.66 0 016.14 10.41c2.58 7.42 2 16.45.37 24.14-8.41-2.33-14.1-10.71-16.72-15.14-4.14-7-7.37-22.17-.7-24'
          style={{
            transformOrigin: '305.292px 428.585px'
          }}
          id='prefix__elncb9l5itrpe'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M327.68 456.54a12.94 12.94 0 01-1.54-12.88 17.14 17.14 0 019.7-9c2-.76 4.26-1.19 6.08-.15a5.52 5.52 0 012.5 5 11.3 11.3 0 01-2 5.47c-3.6 5.68-8.12 10.53-14.77 11.57'
          style={{
            transformOrigin: '334.76px 445.222px'
          }}
          id='prefix__el1bva3su8ssd'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M328.47 476.19a24.49 24.49 0 01-1.41-5 23.93 23.93 0 01-.52-5.66 53.75 53.75 0 01.45-6.91 26 26 0 014.63-11.68 19.66 19.66 0 012.62-2.8c.7-.56 1.12-.84 1.15-.79s-1.46 1.35-3.26 4a27.67 27.67 0 00-4.27 11.44 62.22 62.22 0 00-.51 6.8 26.86 26.86 0 00.32 5.53 38.62 38.62 0 01.8 5.07z'
          style={{
            transformOrigin: '330.961px 459.767px'
          }}
          id='prefix__el6k48ub7mkd4'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M301.28 420.38a14.1 14.1 0 011.37 2.24c.83 1.48 2 3.62 3.42 6.29 2.84 5.33 6.69 12.74 10.74 21s7.5 15.9 9.88 21.45c1.19 2.78 2.13 5 2.75 6.61a16.16 16.16 0 01.88 2.48 14.83 14.83 0 01-1.2-2.34c-.77-1.68-1.78-3.86-3-6.49-2.52-5.49-6.06-13-10.1-21.33s-7.75-15.58-10.5-21.13c-1.29-2.6-2.36-4.75-3.17-6.41a16 16 0 01-1.07-2.37z'
          style={{
            transformOrigin: '315.8px 450.415px'
          }}
          id='prefix__ellm883v8mawe'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          d='M320 463.53a30.25 30.25 0 00-26-9.35c-2.41.33-5.18 1.34-5.79 3.71s1.36 4.59 3.35 6a25.8 25.8 0 0028.63.27'
          style={{
            transformOrigin: '304.144px 461.145px'
          }}
          id='prefix__el6ubj5qooy5u'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M299.2 459.13a4.31 4.31 0 011.38-.15c.88-.05 2.17 0 3.76 0a34 34 0 0112.14 2.62 21.32 21.32 0 015.75 3.81 20.93 20.93 0 015.14 7.81 4 4 0 01.39 1.33c-.16.07-.85-1.78-2.55-4.4a21.9 21.9 0 00-3.53-4.14 22.23 22.23 0 00-5.56-3.61 38.27 38.27 0 00-11.83-2.8 45.72 45.72 0 01-5.09-.47z'
          style={{
            transformOrigin: '313.48px 466.755px'
          }}
          id='prefix__elpefp2w455hj'
          className='prefix__animable'
          fill='#e0e0e0'
        />
        <path
          style={{
            transformOrigin: '51.715px 182.43px'
          }}
          id='prefix__elk4g1juolzrg'
          className='prefix__animable'
          fill='#e0e0e0'
          d='M73.5 199.3l-12.29-25.33-12.34 7.87-18.94-16.28 17.17 25.05 12.5-8.03 13.9 16.72z'
        />
        <path
          style={{
            transformOrigin: '51.185px 205.275px'
          }}
          id='prefix__elamtqh3w76zf'
          className='prefix__animable'
          fill='#e0e0e0'
          d='M69.82 206.25l-17.11-8.35-3.28 9.34-16.88-.76 19.6 6.17 3.3-9.5 14.37 3.1z'
        />
      </g>
      <g
        id='prefix__freepik--Plant--inject-46'
        className='prefix__animable'
        style={{
          transformOrigin: '221.97px 456.622px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight',
          animationDelay: '0s'
        }}
      >
        <path
          d='M401.49 452.87a2.89 2.89 0 01-.63-.13c-.41-.1-1-.3-1.78-.47a42.18 42.18 0 00-16.64-.65 41 41 0 00-30.82 24.67c-.33.73-.51 1.32-.68 1.71a4.35 4.35 0 01-.26.59 2.91 2.91 0 01.16-.62c.13-.4.29-1 .59-1.75a36.9 36.9 0 013-6.12 39.86 39.86 0 0138.05-19.26 36.15 36.15 0 016.69 1.24c.78.19 1.36.43 1.76.56a3.39 3.39 0 01.56.23z'
          style={{
            transformOrigin: '376.085px 464.623px'
          }}
          id='prefix__el4up451pccak'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M398.05 451.33a14.88 14.88 0 00-2.79 15.07c.39 1 1.1 2.08 2.17 2.09s1.85-1.21 2.22-2.29a17.49 17.49 0 00-1.25-13.85 20 20 0 007.32 9c1.24.83 3 1.51 4.16.62s1.05-2.64.57-4a11.79 11.79 0 00-11.75-7.57 11.07 11.07 0 0010.86-6.4c.68-1.59.75-3.84-.78-4.64a3.63 3.63 0 00-3 .23 14.09 14.09 0 00-7.87 8.8 12.4 12.4 0 00-2.3-13.39c-1.07-1.11-2.6-2.06-4.08-1.63-1.86.54-2.48 2.89-2.43 4.83a16.23 16.23 0 006.52 12.42 9.2 9.2 0 00-8.55-.74c-1.8.77-3.48 2.75-2.68 4.53s3.14 1.86 5 1.52a23 23 0 009.28-4'
          style={{
            transformOrigin: '397.489px 450.877px'
          }}
          id='prefix__elq51cpd78els'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M363.63 459.21a30.73 30.73 0 0023.24 11.46c-.15-2-1.7-3.5-3.26-4.69a31.69 31.69 0 00-19.61-6.44'
          style={{
            transformOrigin: '375.25px 464.94px'
          }}
          id='prefix__el3fheega7fqi'
          className='prefix__animable'
          fill='#455a64'
        />
        <path
          d='M397.12 461.92a47.66 47.66 0 00.95-5.79 49.48 49.48 0 00-.28-5.86 15.54 15.54 0 01-.67 11.65z'
          style={{
            transformOrigin: '397.868px 456.095px'
          }}
          id='prefix__el11mm4ru2zgbn'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M404.61 457.74a23.8 23.8 0 00-2.91-3.74c-1.92-1.81-3.89-2.79-3.8-2.94a10.22 10.22 0 014.16 2.56 7.87 7.87 0 012.55 4.12z'
          style={{
            transformOrigin: '401.254px 454.4px'
          }}
          id='prefix__eljavp1kuu56'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M404.24 444.64a8.55 8.55 0 01-2.35 4 7.28 7.28 0 01-4 2.43 21.37 21.37 0 003.61-2.79 44.7 44.7 0 002.74-3.64z'
          style={{
            transformOrigin: '401.065px 447.855px'
          }}
          id='prefix__el6t4rnysdi1c'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M398 448.82a1.81 1.81 0 011.5 3 5.1 5.1 0 00-1.5-.82 7.55 7.55 0 000-2.18'
          style={{
            transformOrigin: '398.975px 450.317px'
          }}
          id='prefix__elzp91cat2mt'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M392.68 438.21a6.3 6.3 0 011.42 1.56 18.74 18.74 0 013.55 9.43 5.81 5.81 0 010 2.1 35.31 35.31 0 00-1.57-6.88 37 37 0 00-3.4-6.21z'
          style={{
            transformOrigin: '395.213px 444.755px'
          }}
          id='prefix__elndaitp4qtc'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M38.34 440.82a8 8 0 015.73 2.5 14.21 14.21 0 013.17 5.53c1.31 3.93.95 8.69 0 12.74-4.42-1.29-7.38-5.74-8.73-8.09-2.14-3.72-3.76-11.74-.22-12.68'
          style={{
            transformOrigin: '42.1546px 451.205px'
          }}
          id='prefix__elhfeapjaim7o'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M53.81 466.05a6.82 6.82 0 01-.73-6.8 9 9 0 015.17-4.7 3.8 3.8 0 013.21 0 2.9 2.9 0 011.28 2.65 5.86 5.86 0 01-1.07 2.8c-1.93 3-4.35 5.5-7.86 6'
          style={{
            transformOrigin: '57.6209px 460.122px'
          }}
          id='prefix__elbyl942i3osj'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M55.1 479.38a5.11 5.11 0 01-.24-.82c-.15-.58-.35-1.34-.58-2.25a24 24 0 01-1-7.62 14.74 14.74 0 012.41-7.27 10.59 10.59 0 011.54-1.76c.2-.19.38-.31.49-.4s.18-.13.19-.12a15.41 15.41 0 00-2 2.4 15.16 15.16 0 00-2.29 7.17 26.08 26.08 0 00.85 7.55c.22 1 .39 1.74.49 2.28a4 4 0 01.14.84z'
          style={{
            transformOrigin: '55.5895px 469.259px'
          }}
          id='prefix__el4oy8rtxaw66'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M40.1 446.82a1.28 1.28 0 01.2.31l.5.94c.44.82 1.05 2 1.79 3.48 1.49 2.95 3.49 7.05 5.6 11.63S52.11 472 53.38 475c.63 1.52 1.13 2.76 1.47 3.62.16.41.28.74.38 1a1.48 1.48 0 01.12.36 1.71 1.71 0 01-.17-.33c-.12-.25-.26-.58-.44-1-.38-.89-.91-2.1-1.56-3.59-1.32-3-3.16-7.2-5.26-11.78s-4.08-8.69-5.52-11.66c-.7-1.46-1.28-2.65-1.7-3.52q-.29-.58-.45-1a2.94 2.94 0 01-.15-.28z'
          style={{
            transformOrigin: '47.725px 463.4px'
          }}
          id='prefix__elnffdmjhd8i'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M49.43 470.86a16 16 0 00-12.81-6.92c-1.29 0-2.81.3-3.32 1.48s.35 2.5 1.28 3.39a13.63 13.63 0 0014.92 2.39'
          style={{
            transformOrigin: '41.3239px 468.187px'
          }}
          id='prefix__elncd0luu0k7'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M38.77 467.25a3.94 3.94 0 01.81 0 6.65 6.65 0 01.94 0c.36.05.78.06 1.24.16a14.06 14.06 0 011.48.31 15.67 15.67 0 011.66.52 17.85 17.85 0 013.55 1.82 17 17 0 013 2.63 15.26 15.26 0 011.08 1.36 12.29 12.29 0 01.82 1.28 12.1 12.1 0 01.58 1.11 6.94 6.94 0 01.37.86 3.74 3.74 0 01.26.77c-.06 0-.44-1.06-1.39-2.63-.25-.39-.51-.82-.84-1.24a15.5 15.5 0 00-1.09-1.32 17.28 17.28 0 00-3-2.57 17.79 17.79 0 00-3.47-1.81 16.32 16.32 0 00-1.63-.54c-.52-.16-1-.25-1.46-.35-1.76-.31-2.91-.3-2.91-.36z'
          style={{
            transformOrigin: '46.665px 472.65px'
          }}
          id='prefix__el0yr90ck8mzbf'
          className='prefix__animable'
          fill='#263238'
        />
      </g>
      <g
        id='prefix__freepik--404-error--inject-46'
        className='prefix__animable'
        style={{
          transformOrigin: '366.385px 382.815px',
          animation:
            '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) lightSpeedLeft',
          animationDelay: '0s'
        }}
      >
        <path
          style={{
            transformOrigin: '356.855px 382.985px'
          }}
          id='prefix__elxw48575a1y'
          className='prefix__animable'
          fill='#455a64'
          d='M323.77 480.07h27.18l38.99-188.78-26.06-5.39-40.11 194.17z'
        />
        <path
          style={{
            transformOrigin: '366.385px 352.91px'
          }}
          id='prefix__el860bx7ebfb4'
          className='prefix__animable'
          fill='#263238'
          d='M440.83 420.26l-164.2-33.92-4.65-4.17 17.36-57.34 7.62.5-11.75-6.26 6.72-33.51 164.2 33.92 4.66 4.18-6.81 32.96-13.01.24 7.16 1.37 4.65 4.17-11.95 57.86z'
        />
        <path
          style={{
            transformOrigin: '364.055px 350.825px'
          }}
          id='prefix__elpse4crhby4'
          className='prefix__animable'
          fill='#455a64'
          d='M436.18 416.09l-164.2-33.92 12.71-61.52 7.62.5-6.36-6.61 5.98-28.98 164.2 33.92-6.81 32.96-13 .24 11.81 5.55-11.95 57.86z'
        />
        <path
          d='M439.83 352.34h-.39l-1.15-.08-4.42-.36-3.19-.28-3.79-.45c-2.71-.34-5.82-.66-9.21-1.22l-5.35-.81c-1.86-.29-3.78-.67-5.77-1-4-.68-8.23-1.57-12.69-2.47-8.91-1.89-18.66-4.18-28.84-6.77-20.35-5.24-38.69-10.28-52.07-13.55-3.33-.85-6.37-1.54-9-2.18s-5-1.16-6.84-1.57l-4.32-1-1.12-.27a2.25 2.25 0 01-.38-.12 1.78 1.78 0 01.39.06l1.14.21 4.34.87c1.89.38 4.2.87 6.86 1.48s5.71 1.27 9.06 2.09c13.4 3.16 31.77 8.14 52.11 13.37 10.18 2.59 19.91 4.9 28.8 6.82 4.46.91 8.68 1.82 12.67 2.52 2 .36 3.91.74 5.76 1l5.33.85c3.39.59 6.48.94 9.19 1.31l3.78.5 3.18.33 4.41.48 1.14.14z'
          style={{
            transformOrigin: '365.565px 336.275px'
          }}
          id='prefix__eldo9oy5bltn6'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M448.08 347.09a3.52 3.52 0 01-.37-.24l-1-.75c-.91-.65-2.21-1.68-4-2.9a147.3 147.3 0 00-15.38-9.44 145.54 145.54 0 00-24.67-10.26 141.35 141.35 0 00-32.13-5.92c-11.61-.85-22.69.28-32.78.51a129.21 129.21 0 01-14.35-.29 97.76 97.76 0 01-29.63-7.21c-2-.83-3.47-1.53-4.48-2l-1.14-.58-.38-.21a1.85 1.85 0 01.41.16l1.17.52c1 .46 2.53 1.13 4.51 1.92a101.42 101.42 0 0017.26 5.19 99.57 99.57 0 0012.32 1.74 133.67 133.67 0 0014.29.23c10.06-.26 21.17-1.41 32.84-.56a140.18 140.18 0 0132.26 6 144.05 144.05 0 0124.71 10.42 137.37 137.37 0 0115.31 9.58c1.73 1.24 3 2.31 3.9 3l1 .8a2.85 2.85 0 01.33.29z'
          style={{
            transformOrigin: '367.925px 327.445px'
          }}
          id='prefix__elahs0j4wthz'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M368.71 303a4.21 4.21 0 01-.77.27l-2.25.67c-1 .3-2.17.63-3.58.95s-3 .76-4.8 1.08-3.74.75-5.87 1-4.39.6-6.78.87a136.49 136.49 0 01-15.66.61 140.52 140.52 0 01-15.62-1.29c-2.38-.38-4.64-.71-6.74-1.17s-4-.86-5.81-1.3-3.35-.89-4.74-1.29-2.58-.77-3.54-1.1l-2.22-.77a4.93 4.93 0 01-.76-.3 3.89 3.89 0 01.8.18l2.25.66c1 .3 2.16.65 3.56 1s3 .83 4.75 1.2 3.71.85 5.81 1.22 4.35.75 6.72 1.11a147.47 147.47 0 0015.54 1.23 145.67 145.67 0 0015.59-.56c2.38-.26 4.65-.47 6.76-.81s4.07-.63 5.86-1 3.39-.69 4.8-1 2.62-.59 3.6-.85l2.27-.55a3.74 3.74 0 01.83-.06z'
          style={{
            transformOrigin: '329.14px 304.852px'
          }}
          id='prefix__elci7l6c1qtkc'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M325 361.66s.26.14.74.44l2.16 1.33c1.9 1.15 4.68 2.91 8.4 4.9a55.78 55.78 0 0014.28 5.53 34.62 34.62 0 004.57.7 7.14 7.14 0 004.62-1 3.72 3.72 0 001.38-2 5 5 0 000-2.53 7.76 7.76 0 00-3.15-4.23c-12.52-8.3-30.15-14.35-50.1-14.91-2.49-.08-5-.08-7.56 0s-5.16.68-6.87 2.58a4.69 4.69 0 00-1.31 3.38 6.09 6.09 0 001.4 3.46c1.61 2.06 3.93 3.49 6.16 5 9 6.1 18.53 12.16 29.25 16.36s22.11 6.09 32.84 4.5a47.35 47.35 0 007.8-1.84 13.5 13.5 0 006.47-4.08 11.44 11.44 0 002.31-7.07 16.69 16.69 0 00-1.62-7.22 26.05 26.05 0 00-9.66-10.49 46.9 46.9 0 00-12.53-5.41c-4.25-1.23-8.46-2.11-12.48-3l-11.57-2.62-34.91-7.93-9.48-2.19-2.46-.58c-.56-.13-.84-.22-.84-.22l.85.16 2.48.52 9.51 2.08 34.95 7.74 11.57 2.58c4 .91 8.24 1.77 12.52 3a47.2 47.2 0 0112.68 5.45 26.56 26.56 0 019.88 10.7 17.29 17.29 0 011.68 7.46 12 12 0 01-2.42 7.43 14.09 14.09 0 01-6.74 4.28 46.47 46.47 0 01-7.91 1.87c-10.86 1.62-22.39-.31-33.14-4.52s-20.35-10.27-29.33-16.4c-2.22-1.54-4.58-3-6.28-5.17a6.63 6.63 0 01-1.53-3.8 5.28 5.28 0 011.47-3.79 7.77 7.77 0 013.39-2.12 15.28 15.28 0 013.87-.64c2.58-.1 5.11-.09 7.61 0 20.07.61 37.75 6.71 50.35 15.13a8.16 8.16 0 013.29 4.48 5.41 5.41 0 010 2.74 4 4 0 01-1.53 2.18 7.55 7.55 0 01-4.87 1.07 36.71 36.71 0 01-4.63-.73 55.47 55.47 0 01-14.31-5.66c-3.72-2-6.48-3.82-8.36-5-.92-.59-1.63-1-2.12-1.38s-.77-.52-.77-.52z'
          style={{
            transformOrigin: '330.901px 359.44px'
          }}
          id='prefix__el97k8io6zarb'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M444.89 372.33a6.37 6.37 0 01-1-.19c-.62-.17-1.55-.35-2.74-.56a34.29 34.29 0 00-10.24.08 52.45 52.45 0 00-14.45 4.66 143.53 143.53 0 00-16.06 9.56 130.63 130.63 0 01-16.21 9.5 50.19 50.19 0 01-14.68 4.4 32.08 32.08 0 01-10.33-.29 22.43 22.43 0 01-2.72-.72 5.29 5.29 0 01-.92-.34 6.75 6.75 0 01.95.22c.62.18 1.54.38 2.73.61a33.52 33.52 0 0010.23.09 50.74 50.74 0 0014.5-4.48 134.85 134.85 0 0016.11-9.5 139.21 139.21 0 0116.16-9.56 51.3 51.3 0 0114.63-4.58 32.78 32.78 0 0110.34.12 23.08 23.08 0 012.81.65 5.11 5.11 0 01.89.33z'
          style={{
            transformOrigin: '400.215px 385.472px'
          }}
          id='prefix__elwpuiojt9hs'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M441.85 388.61c0 .06-.78-.26-2.22-.58a17.16 17.16 0 00-6.19-.2 35.33 35.33 0 00-8.83 2.72 81.1 81.1 0 00-10.07 5.27c-3.48 2.06-6.8 4.07-10 5.6a41.24 41.24 0 01-8.81 3.17 25.28 25.28 0 01-6.28.62 13.64 13.64 0 01-1.71-.15 2.2 2.2 0 01-.59-.11c0-.07.83 0 2.3 0a27.46 27.46 0 006.19-.8 43.15 43.15 0 008.66-3.26c3.14-1.54 6.43-3.55 9.92-5.62A76.11 76.11 0 01424.4 390a34.06 34.06 0 019-2.63 16.29 16.29 0 016.3.4 14.23 14.23 0 011.63.55 2.12 2.12 0 01.52.29z'
          style={{
            transformOrigin: '414.5px 396.224px'
          }}
          id='prefix__eltya7ge8y0hj'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M389 305.75c0 .17-6.33-1.07-14.06-2.77s-14-3.21-13.93-3.37 6.33 1.07 14.06 2.77 13.93 3.21 13.93 3.37z'
          style={{
            transformOrigin: '375.005px 302.681px'
          }}
          id='prefix__elw2wlxec0v8'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M343.51 343.69l-5.14-1.12-1.44 6.62-9.55-2.07 1.44-6.63-18.29-4 1.39-6.38 20.23-18.22 10.14 2.2-18.5 17.16 7 1.52 1.28-5.88 9.25 2-1.28 5.89 5.14 1.12z'
          style={{
            transformOrigin: '327.855px 330.54px'
          }}
          id='prefix__el9may3dfqqe8'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M347.82 333.44c2.48-11.44 10.34-16.59 19.18-14.67s13.8 9.89 11.31 21.31-10.35 16.6-19.14 14.68-13.84-9.9-11.35-21.32zm20.66 4.5c1.58-7.26-.1-10.48-3.21-11.16s-6 1.54-7.62 8.8.11 10.49 3.27 11.18 5.98-1.55 7.56-8.82z'
          style={{
            transformOrigin: '363.068px 336.764px'
          }}
          id='prefix__elnv6yntx7vto'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M412 358.6l-5.14-1.12-1.44 6.63-9.56-2.11 1.45-6.63-18.29-4 1.39-6.38 20.22-18.22 10.14 2.23-18.49 17.15 7 1.53 1.28-5.89 9.24 2-1.28 5.88 5.14 1.12z'
          style={{
            transformOrigin: '396.34px 345.44px'
          }}
          id='prefix__eliai97s8oyw'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M336.19 368.23l-.51 2.17-10.41-2.4 3.25-13.88 10.14 2.37-.51 2.16-7.55-1.77-.85 3.61 6.7 1.58-.49 2.12-6.71-1.57-.9 3.83z'
          style={{
            transformOrigin: '331.965px 362.26px'
          }}
          id='prefix__elq7lrlve9pjs'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M347.65 373.2l-1.88-4.73a4.29 4.29 0 01-.54-.11l-3.14-.73-.95 4-2.58-.6 3.26-13.89 5.71 1.34c3.65.86 5.48 3.25 4.76 6.33a4.57 4.57 0 01-4 3.69l2.13 5.31zm-.75-12.52l-3-.71-1.3 5.54 3 .7c2.26.53 3.68-.23 4.09-2s-.53-3-2.79-3.53z'
          style={{
            transformOrigin: '345.502px 365.475px'
          }}
          id='prefix__elwiz3f6ee13'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M362.15 376.6l-1.88-4.73a5.17 5.17 0 01-.54-.11l-3.13-.76-1 4.05-2.58-.61 3.25-13.89 5.72 1.34c3.65.86 5.47 3.25 4.75 6.33a4.56 4.56 0 01-4 3.69l2.12 5.31zm-.74-12.52l-3-.71-1.3 5.54 3 .7c2.26.53 3.67-.23 4.08-2s-.52-3-2.78-3.53z'
          style={{
            transformOrigin: '359.958px 368.885px'
          }}
          id='prefix__elf5wtm8h4zdt'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M368.21 370.69c1-4.1 4.85-6.39 9.15-5.38s6.78 4.77 5.81 8.89-4.85 6.4-9.15 5.39-6.77-4.79-5.81-8.9zm12.36 2.9a5 5 0 10-6 3.74 4.86 4.86 0 006-3.74z'
          style={{
            transformOrigin: '375.692px 372.45px'
          }}
          id='prefix__el0dwofurl569n'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M393.37 383.92l-1.88-4.73-.55-.11-3.13-.73-.95 4-2.58-.6 3.26-13.89 5.71 1.34c3.65.86 5.48 3.25 4.76 6.33a4.58 4.58 0 01-4 3.69l2.13 5.31zm-.75-12.53l-3-.7-1.3 5.53 3 .71c2.27.53 3.68-.23 4.09-2s-.53-3.01-2.79-3.54z'
          style={{
            transformOrigin: '391.222px 376.195px'
          }}
          id='prefix__elyk1t6padooh'
          className='prefix__animable'
          fill='#fff'
        />
      </g>
      <g
        id='prefix__freepik--Ballon--inject-46'
        className='prefix__animable'
        style={{
          transformOrigin: '319.27px 157.814px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft',
          animationDelay: '0s'
        }}
      >
        <path
          d='M348.66 143.24a2.56 2.56 0 01.38.19l1.07.61a30.24 30.24 0 013.83 2.78 34 34 0 019.85 14.2 35.77 35.77 0 012.1 11.84 38.69 38.69 0 01-2.37 13.59 37.24 37.24 0 01-8 13 30.11 30.11 0 01-6.31 5.08 28 28 0 01-7.72 3.17 51.84 51.84 0 01-16.34.95c-5.29-.39-10.34-1.08-15.18-1.3a45.71 45.71 0 00-13.63 1.11 25.79 25.79 0 00-10.57 5.27 17.47 17.47 0 00-5.38 8.06 16.3 16.3 0 00-.69 7.33 13 13 0 001.46 4.44c.53.94.88 1.39.85 1.41a11.52 11.52 0 01-.94-1.36 12.66 12.66 0 01-1.57-4.46 16.26 16.26 0 01.61-7.45 17.72 17.72 0 015.42-8.25 26 26 0 0110.71-5.4 45.52 45.52 0 0113.76-1.19c4.87.21 9.93.89 15.19 1.27a51.66 51.66 0 0016.19-.94 27.89 27.89 0 007.58-3.11 29.9 29.9 0 006.2-5 36.78 36.78 0 007.88-12.8 38.48 38.48 0 002.39-13.43 35.4 35.4 0 00-2-11.71 33.68 33.68 0 00-13.39-17l-1-.65z'
          style={{
            transformOrigin: '322.604px 189.105px'
          }}
          id='prefix__el199drb9anzc'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M279.48 17c2.6 7.54 5.21 15.2 5.58 23.16s-1.84 16.39-7.62 21.84c-6.7 6.36-16.64 7.57-25.83 8.46 6.61.23 13.86 1.8 17.82 7.1 4.54 6.07 3.06 14.75 0 21.67s-7.65 13.37-8.9 20.85a34.59 34.59 0 0153.09 29.74c5.69-10.73 15.45-19.51 27.14-22.8s25.17-.6 33.91 7.83a44.8 44.8 0 0112.26-63c-5.32 4.42-13.64 3.89-19.3-.09s-8.88-10.7-10.08-17.51-.61-13.79 0-20.68c-6 7.79-18.43 9.56-26.35 3.74-8.12-6-10.17-17-11.6-27 .74 9.78-6.75 19.49-16.4 21.26s-20.09-4.66-22.87-14.06'
          style={{
            transformOrigin: '319.27px 80.065px'
          }}
          id='prefix__elf9swkr3suv'
          className='prefix__animable'
          fill='#f5f5f5'
        />
        <path
          d='M343.68 137.57c-.71 4.71.6 9.53 2.06 14.06A5.9 5.9 0 00347 154a2.31 2.31 0 002.47.65 3.14 3.14 0 001.48-2 16.84 16.84 0 00.91-5.45c1.49 1.1 3.1 2.25 5 2.34s3.89-1.42 3.6-3.25a4.73 4.73 0 00-1.75-2.54l-9.89-9.13-5.07 2.95'
          style={{
            transformOrigin: '351.985px 144.697px'
          }}
          id='prefix__elvhokvs4eika'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M290.88 42.4s-26.31 19.42-18.26 50.47l2.65.33 9.29-9.62 12.8 17.78-1.78-18.13 5.48 4 9.39-19.62s-14-10-12-19.79c.04-.04-8.19-.5-7.57-5.42z'
          style={{
            transformOrigin: '290.769px 71.88px'
          }}
          id='prefix__eld6nczslcexc'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M280.64 108A37.45 37.45 0 00292 129.24l15.62-9.41s-20.3-3.7-26.98-11.83z'
          style={{
            transformOrigin: '294.13px 118.62px'
          }}
          id='prefix__el6br2nc2uxas'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M313.37 128.76s7.21 11.61 25.52 11c13-.44 21.39-17 18.15-32.83l-10.6 8-3.93-2.56v8.36s-4.27 6.17-9.79 2.69c.02 0-4.78 10.2-19.35 5.34z'
          style={{
            transformOrigin: '335.554px 123.357px'
          }}
          id='prefix__elq9v4r8pkd1m'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M320.12 39.05c-.62.27 12.51 15.74 12.62 25 0 0 4.33-8.45 13.16-8.81 0 0 .32-7.32 7.45-5.79 0-.02-15.23-18.16-33.23-10.4z'
          style={{
            transformOrigin: '336.724px 50.5848px'
          }}
          id='prefix__elpw7agnv0d0k'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M362.81 69.31s6.21 10.9 6 22c0 0-8.95-9.21-16.18-2.73l-1.81-4.37s4.27-.85 4.25-6-5.07-8.16-5.07-8.16 9.79 8.21 12.81-.74z'
          style={{
            transformOrigin: '359.408px 80.31px'
          }}
          id='prefix__elugnddglvq4q'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M309.29 96.09c0 .14-11.4 3-25.54 6.42s-25.63 6-25.66 5.91 11.4-3 25.54-6.42 25.63-6 25.66-5.91z'
          style={{
            transformOrigin: '283.69px 102.255px'
          }}
          id='prefix__el1oyi6ttao73'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M312.29 78c-.05.13-6.2-2-13.75-4.71s-13.62-5-13.57-5.19 6.2 2 13.75 4.71 13.62 5.06 13.57 5.19z'
          style={{
            transformOrigin: '298.63px 73.0471px'
          }}
          id='prefix__elcgu7e1uzfew'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M303.28 10.52c.14 0 4.93 13.46 10.71 30.17S324.34 71 324.2 71s-4.93-13.45-10.7-30.16-10.35-30.27-10.22-30.32z'
          style={{
            transformOrigin: '313.74px 40.76px'
          }}
          id='prefix__elgf841awhz4c'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M341.38 49.33a15 15 0 01-.09 3c-.14 1.82-.42 4.34-.88 7.1s-1 5.23-1.46 7a14.52 14.52 0 01-.88 2.83 20.49 20.49 0 01.52-2.92c.37-1.79.85-4.26 1.31-7s.79-5.24 1-7.05a18.33 18.33 0 01.48-2.96z'
          style={{
            transformOrigin: '339.743px 59.295px'
          }}
          id='prefix__elmmokydgyvc'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M379.5 51.23c.07.13-8.68 5.37-19.55 11.71s-19.73 11.38-19.8 11.25 8.67-5.36 19.54-11.7 19.73-11.38 19.81-11.26z'
          style={{
            transformOrigin: '359.825px 62.7102px'
          }}
          id='prefix__el0s79l53ww39b'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M379.6 99.87a7.15 7.15 0 01-1.45-.1c-.93-.11-2.28-.27-3.93-.5-3.32-.45-7.89-1.14-12.93-2s-9.57-1.76-12.84-2.46c-1.64-.34-3-.64-3.87-.86a6.54 6.54 0 01-1.4-.39 8.78 8.78 0 011.44.2l3.9.69 12.86 2.31 12.89 2.16 3.91.65a7.59 7.59 0 011.42.3z'
          style={{
            transformOrigin: '361.39px 96.7169px'
          }}
          id='prefix__elr59vsdrxd6k'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M365.14 143.05c-.11.1-7.73-8.2-17-18.54s-16.75-18.81-16.64-18.9 7.73 8.21 17 18.55 16.75 18.84 16.64 18.89z'
          style={{
            transformOrigin: '348.32px 124.33px'
          }}
          id='prefix__el6wjeada273u'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M304.51 139.34a4.88 4.88 0 01.37-1.14c.26-.73.67-1.77 1.19-3 1-2.55 2.53-6.06 4.26-9.9s3.38-7.29 4.6-9.76c.62-1.23 1.13-2.23 1.49-2.91a4.76 4.76 0 01.62-1 6.13 6.13 0 01-.44 1.12l-1.33 3c-1.19 2.62-2.75 6-4.46 9.81s-3.23 7.22-4.4 9.84c-.53 1.18-1 2.17-1.35 3a4.92 4.92 0 01-.55.94z'
          style={{
            transformOrigin: '310.775px 125.485px'
          }}
          id='prefix__elzmuq6zdtir'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M312.26 89c0-.09.8-.17 2-.65a8.64 8.64 0 005.46-7.61c.07-1.27-.11-2.07 0-2.08a4.29 4.29 0 01.39 2.09 8.24 8.24 0 01-5.69 7.94 4.31 4.31 0 01-2.16.31z'
          style={{
            transformOrigin: '316.19px 83.8438px'
          }}
          id='prefix__ell9j0nythun'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M320.55 103.2c-.08 0 .35-.71.58-1.93a6.22 6.22 0 00-4.55-7.27c-1.18-.34-2-.25-2-.34a3.54 3.54 0 012.07 0 6.24 6.24 0 014.82 7.76 3.52 3.52 0 01-.92 1.78z'
          style={{
            transformOrigin: '318.134px 98.3527px'
          }}
          id='prefix__el7xea1b9oool'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M340.05 94.72c0 .08-.88-.25-2.31-.42a11.59 11.59 0 00-5.49.7 11.47 11.47 0 00-4.47 3.26c-.93 1.11-1.32 1.93-1.4 1.89a5.4 5.4 0 011.11-2.15 10.53 10.53 0 0110.3-4.09 5.45 5.45 0 012.26.81z'
          style={{
            transformOrigin: '333.215px 96.9492px'
          }}
          id='prefix__elsfksve26tm'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M339.52 88.88a3.19 3.19 0 01-1.72-.38 6.4 6.4 0 01-3.07-2.93 6.32 6.32 0 01-.48-4.22 3 3 0 01.73-1.59c.08 0-.22.63-.37 1.66a6.7 6.7 0 00.59 3.92 6.78 6.78 0 002.8 2.83c.88.5 1.53.62 1.52.71z'
          style={{
            transformOrigin: '336.802px 84.3231px'
          }}
          id='prefix__elwjw3lqioq8p'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M333.62 76.3a5 5 0 01-1.29 1.06 7.6 7.6 0 01-3.78 1.47 4.19 4.19 0 01-3.65-1.73c-.6-.95-.39-1.68-.35-1.65a5.19 5.19 0 00.65 1.43 4 4 0 003.32 1.43 8.79 8.79 0 003.62-1.26 5.52 5.52 0 011.48-.75z'
          style={{
            transformOrigin: '329.051px 77.1434px'
          }}
          id='prefix__elahr99c950rd'
          className='prefix__animable'
          fill='#263238'
        />
        <g
          style={{
            transformOrigin: '347.326px 138.885px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elqknxeauhei'
        >
          <path
            d='M343.54 138.85a25.21 25.21 0 005.78-3.71l1.85 1.71c-2.52 1.81-5.44 3.51-7.56 5.78a20.21 20.21 0 01-.07-3.78'
            id='prefix__el3rm10get30o'
            className='prefix__animable'
            style={{
              transformOrigin: '347.326px 138.885px'
            }}
          />
        </g>
        <path
          d='M349.87 134.54A10.23 10.23 0 01343 139c0-.15 1.72-.71 3.59-1.94s3.16-2.62 3.28-2.52z'
          style={{
            transformOrigin: '346.435px 136.767px'
          }}
          id='prefix__elr2t7e0vlv4'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M345.87 134.79a6.07 6.07 0 01-1.07.54 12.31 12.31 0 01-1.31.52 17.93 17.93 0 01-1.78.57 27.51 27.51 0 01-4.78.88 31.36 31.36 0 01-5.95-.07 32 32 0 01-5.82-1.3 26.93 26.93 0 01-4.45-2c-.62-.31-1.13-.67-1.6-1a8.94 8.94 0 01-1.15-.81 5.55 5.55 0 01-.92-.77c.07-.08 1.41 1 3.85 2.22a29.76 29.76 0 004.42 1.82 30.19 30.19 0 0011.58 1.35 30.23 30.23 0 004.73-.76c2.65-.57 4.2-1.29 4.25-1.19z'
          style={{
            transformOrigin: '331.455px 134.377px'
          }}
          id='prefix__elnpzzlsmiyf'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M346.45 151.25a9.13 9.13 0 01-1.34-3.87 9.24 9.24 0 010-4.1c.16 0 .15 1.82.53 4s.95 3.91.81 3.97z'
          style={{
            transformOrigin: '345.676px 147.265px'
          }}
          id='prefix__el7cu4e3h2rgv'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M290.68 124.67a7.25 7.25 0 01-1.7-1.42 30 30 0 01-6.11-8.76 7.74 7.74 0 01-.75-2.09c.15-.07 1.46 3 3.84 6.44s4.83 5.72 4.72 5.83z'
          style={{
            transformOrigin: '286.402px 118.534px'
          }}
          id='prefix__elep7ir0trrnl'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M281.29 57.78c.08.06-.93 1.56-2.26 4.14a48.63 48.63 0 00-5.41 21.79c0 2.91.15 4.7.06 4.71a5.53 5.53 0 01-.21-1.25 32.47 32.47 0 01-.22-3.46 44.06 44.06 0 011.3-11.34 44.75 44.75 0 014.15-10.63c.72-1.27 1.34-2.28 1.82-3a5.45 5.45 0 01.77-.96z'
          style={{
            transformOrigin: '277.27px 73.1px'
          }}
          id='prefix__el1t0avxry6jn'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M288.65 49.1c.09.11-.53.78-1.4 1.49s-1.64 1.19-1.73 1.08.54-.78 1.4-1.49 1.64-1.18 1.73-1.08z'
          style={{
            transformOrigin: '287.085px 50.3861px'
          }}
          id='prefix__el45cttafw6sv'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M309.09 294.64a2.17 2.17 0 01.56.66 4 4 0 01.53 2.43 6 6 0 01-1.71 3.53 12.86 12.86 0 01-4.28 3 12.53 12.53 0 01-6.24 1 11.91 11.91 0 01-6.6-3 11.68 11.68 0 01-3.57-7.13 16.14 16.14 0 011.27-8.5 27.49 27.49 0 014.67-7.22c1.82-2.1 3.72-4 5.24-6a15.94 15.94 0 003.13-6.32 14 14 0 00-4.55-13.73 20.29 20.29 0 00-2.71-1.91 10.55 10.55 0 012.84 1.75 14.16 14.16 0 014.79 7.66 14.57 14.57 0 01.06 6.32 16.29 16.29 0 01-3.18 6.52c-1.54 2.05-3.44 3.94-5.23 6a27.37 27.37 0 00-4.58 7.1 15.63 15.63 0 00-1.24 8.22 11.19 11.19 0 003.39 6.85 11.49 11.49 0 006.32 2.9 12.33 12.33 0 006.05-.88c3.48-1.47 5.77-4 5.93-6.19a4.08 4.08 0 00-.42-2.35c-.26-.49-.5-.69-.47-.71z'
          style={{
            transformOrigin: '298.931px 278.384px'
          }}
          id='prefix__elolwkauuf5rj'
          className='prefix__animable'
          fill='#263238'
        />
      </g>
      <g
        id='prefix__freepik--Character--inject-46'
        className='prefix__animable'
        style={{
          transformOrigin: '186.591px 312.118px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn',
          animationDelay: '0s'
        }}
      >
        <path
          d='M106.55 427.8c2.94-13.71 19.73-113.21 19.73-113.21s-12.11 1.92-14.55 6.26C109 325.62 96 398.93 94.54 423.8a23.9 23.9 0 00-3.59 0 13 13 0 00-8.17 3.46 9.85 9.85 0 00-2.18 10.08 9.73 9.73 0 003.49 4.47c-.19 2.13.49 4.74 2.16 5.83a5.8 5.8 0 006.46-.17 3.58 3.58 0 006.43.85c.8 1.86 3.42 2 5.31 1.34a11.38 11.38 0 007-8.3 14.09 14.09 0 00-2.34-10.74 17 17 0 00-2.56-2.82z'
          style={{
            transformOrigin: '103.161px 382.305px'
          }}
          id='prefix__elw18hkwdonqp'
          className='prefix__animable'
          fill='#ff725e'
        />
        <g
          style={{
            transformOrigin: '111.685px 370.385px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elxugr7ox6p4b'
        >
          <path
            d='M109 413.53c-7.16-12.58-10.81-26-5.38-45.46 3.88-13.95 11.62-28.22 18.74-40.83'
            id='prefix__ela10xdy23ife'
            className='prefix__animable'
            style={{
              transformOrigin: '111.685px 370.385px'
            }}
          />
        </g>
        <path
          d='M105.08 440.27a5.7 5.7 0 00.88-.76 3.35 3.35 0 00.72-2.91 3.91 3.91 0 00-3.25-2.81A5.45 5.45 0 0098.5 436a9.14 9.14 0 00-1.85 5.33 10.68 10.68 0 00.86 4.6 11.92 11.92 0 002.52 3.55s-.1 0-.27-.16a4.3 4.3 0 01-.68-.57 8.92 8.92 0 01-1.91-2.67 10.79 10.79 0 01-1-4.77 9.52 9.52 0 011.92-5.64 5.92 5.92 0 015.41-2.33 4.29 4.29 0 013.54 3.21 3.52 3.52 0 01-.93 3.13 2.8 2.8 0 01-.73.53c-.19.05-.3.08-.3.06z'
          style={{
            transformOrigin: '101.637px 441.391px'
          }}
          id='prefix__elhgybini5ol'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M82.93 441.16a8 8 0 011 .53 7.86 7.86 0 002.87.81 7.66 7.66 0 004.26-.83 6 6 0 002-1.62 3.27 3.27 0 00.66-2.51 8.06 8.06 0 00-2.42-4.2 9.77 9.77 0 00-3.51-2.17l-.61-.22.63-.16a28.17 28.17 0 004.55-1.72 4.47 4.47 0 01-1.11.74 15.39 15.39 0 01-3.35 1.36v-.38a10 10 0 013.71 2.2 9.67 9.67 0 011.61 1.95 6.65 6.65 0 011 2.53 3.76 3.76 0 01-.77 2.89 6.47 6.47 0 01-2.16 1.74 7.84 7.84 0 01-4.52.77 7.28 7.28 0 01-2.93-1 4.33 4.33 0 01-.67-.49q-.25-.2-.24-.22z'
          style={{
            transformOrigin: '88.5955px 436.002px'
          }}
          id='prefix__ell9tajnrssx9'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M92.05 441.33c.17.06-.43 1.49-.2 3.28a24.88 24.88 0 001 3.15 4.65 4.65 0 01-1.49-3.08 4.43 4.43 0 01.69-3.35z'
          style={{
            transformOrigin: '92.0638px 444.545px'
          }}
          id='prefix__eltwhj6j9n3r'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M99.42 434.31a4.48 4.48 0 00-.46-1.22 2.43 2.43 0 00-1-.85 2.66 2.66 0 00-1.62-.14 4.54 4.54 0 00-2.55 1.71c-.51.65-.76 1.11-.83 1.07s.07-.54.53-1.28a4.35 4.35 0 012.74-2 3 3 0 011.94.21 2.64 2.64 0 011.14 1.1c.4.83.16 1.43.11 1.4z'
          style={{
            transformOrigin: '96.24px 433.204px'
          }}
          id='prefix__elhsjly7njh5e'
          className='prefix__animable'
          fill='#263238'
        />
        <g
          style={{
            transformOrigin: '97.085px 432.331px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elw3irpwowmvf'
        >
          <path
            d='M88.23 430.86a4.79 4.79 0 014.6 1.24 7.26 7.26 0 014-2.46 3.27 3.27 0 013.73 2.23 4 4 0 015.61 3.21 5.54 5.54 0 00-6.91-.78 2.37 2.37 0 00-1.68-2.2 3.37 3.37 0 00-2.83.52 5.83 5.83 0 00-1.86 2.28 9.18 9.18 0 00-4.89-4.07'
            id='prefix__elz53u4b2gwd'
            className='prefix__animable'
            style={{
              transformOrigin: '97.085px 432.331px'
            }}
          />
        </g>
        <path
          d='M84.75 440.6a4.68 4.68 0 01-1.75-1.43 8.65 8.65 0 01-.76-10.47 4.74 4.74 0 011.52-1.7c.07.06-.57.67-1.25 1.87a9 9 0 00.73 10.05c.85 1.08 1.57 1.61 1.51 1.68z'
          style={{
            transformOrigin: '82.7905px 433.8px'
          }}
          id='prefix__elhfp0sscly0k'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M301.61 245.25c-.66-1.91-2.46-3.82-4.4-3.27a3.57 3.57 0 00-4.73-4.43 5.8 5.8 0 00-4-5.12c-1.91-.6-4.35.52-5.88 2a9.62 9.62 0 00-5.67.13 9.83 9.83 0 00-6.43 8 13 13 0 002.49 8.52 33.68 33.68 0 005.24 5.7c-10.3 48.09-63.07 56.45-63.07 56.45l4.95 19.36c50.57-12.49 64.5-52.81 68.29-71.75h1.33a14.12 14.12 0 009.8-5 11.37 11.37 0 002.08-10.59z'
          style={{
            transformOrigin: '258.64px 282.427px'
          }}
          id='prefix__el181qalv1uq3'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M294.73 251.68a.85.85 0 01.11.29 2.64 2.64 0 01.06.91 3.5 3.5 0 01-1.83 2.69 4.29 4.29 0 01-4.73-.71 5.89 5.89 0 01-1.62-5.66 9.53 9.53 0 013.15-5.06 10.78 10.78 0 014.33-2.24 8.87 8.87 0 013.28-.21 4 4 0 01.87.17c.2 0 .3.09.29.1a12.05 12.05 0 00-4.34.3 10.88 10.88 0 00-4.12 2.24 9.22 9.22 0 00-2.95 4.81 5.45 5.45 0 001.43 5.19 3.91 3.91 0 004.24.75 3.4 3.4 0 001.8-2.4 8.65 8.65 0 00.03-1.17z'
          style={{
            transformOrigin: '292.593px 248.803px'
          }}
          id='prefix__elces2i5h8s'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M281.4 234h.29a5.16 5.16 0 01.82.22 7.29 7.29 0 012.63 1.62 7.91 7.91 0 012.26 4 6.26 6.26 0 010 2.76 3.8 3.8 0 01-1.75 2.43 6.65 6.65 0 01-2.6.83 10 10 0 01-6.57-1.5l.28-.25a16.21 16.21 0 011.07 3.45 4.06 4.06 0 01.12 1.33 26.41 26.41 0 00-1.55-4.62l-.27-.58.56.34a9.71 9.71 0 003.9 1.33 8 8 0 004.79-.78 3.27 3.27 0 001.52-2.1 5.88 5.88 0 000-2.54 7.71 7.71 0 00-2.05-3.83 7.94 7.94 0 00-2.45-1.71c-.62-.3-1.01-.4-1-.4z'
          style={{
            transformOrigin: '281.842px 241.445px'
          }}
          id='prefix__el53191dq1zh'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M287.31 240.92a4.42 4.42 0 012.14-2.67 4.68 4.68 0 013.33-.79 23.12 23.12 0 00-3.06 1.23c-1.53.95-2.27 2.31-2.41 2.23z'
          style={{
            transformOrigin: '290.045px 239.168px'
          }}
          id='prefix__elic9cx3b5wye'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M286.53 251.07c.05 0-.26.59-1.18.78a2.73 2.73 0 01-1.58-.19 3 3 0 01-1.39-1.37 4.37 4.37 0 01-.18-3.39c.29-.82.6-1.26.66-1.23a6.87 6.87 0 01-.3 1.33 4.5 4.5 0 00.28 3.05 2.69 2.69 0 001.14 1.17 2.32 2.32 0 001.31.26 4 4 0 001.24-.41z'
          style={{
            transformOrigin: '284.238px 248.78px'
          }}
          id='prefix__elsomppmcrgj'
          className='prefix__animable'
          fill='#263238'
        />
        <g
          style={{
            transformOrigin: '283.975px 250.452px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__el99pjzzropv5'
        >
          <path
            d='M276.77 244.59a4.79 4.79 0 003.88 2.77 7.31 7.31 0 00.63 4.67c.82 1.37 2.68 2.23 4.09 1.48a4 4 0 006 2.31 5.55 5.55 0 01-5-4.86 2.37 2.37 0 01-2.77.09 3.33 3.33 0 01-1.38-2.52 5.76 5.76 0 01.58-2.88 9.15 9.15 0 01-6.22-1.19'
            id='prefix__elld0ipow046'
            className='prefix__animable'
            style={{
              transformOrigin: '283.975px 250.452px'
            }}
          />
        </g>
        <path
          d='M147.66 431.71q4.77 20.22 9.52 40.45a22.65 22.65 0 00-14.29 2.66c-1.5.85-3.09 2.52-2.28 4 .63 1.2 2.28 1.29 3.63 1.23 10.52-.45 21 .44 31.58 0l-.58-41'
          style={{
            transformOrigin: '158.106px 455.943px'
          }}
          id='prefix__elhugpn1mwsds'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M204.7 431.71q-4.75 20.22-9.52 40.45a22.67 22.67 0 0114.3 2.66c1.5.85 3.08 2.52 2.28 4-.64 1.2-2.28 1.29-3.63 1.23-10.53-.45-21 .44-31.58 0l.58-41'
          style={{
            transformOrigin: '194.263px 455.943px'
          }}
          id='prefix__elmf3tv26lr4'
          className='prefix__animable'
          fill='#ff725e'
        />
        <g
          style={{
            transformOrigin: '162.615px 443.779px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__el5g3fe630zth'
        >
          <path
            d='M149.88 441.13l1.31 5.35c8.6.47 16.94 1.77 24.16.18l-.07-4.72s-8.9-.47-14-1.81l2.33 4.27a63.54 63.54 0 01-13.73-3.27z'
            id='prefix__el3k0i1oze8k5'
            className='prefix__animable'
            style={{
              transformOrigin: '162.615px 443.779px'
            }}
          />
        </g>
        <g
          style={{
            transformOrigin: '190.35px 441.961px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__eljpuexvb815'
        >
          <path
            d='M177 446.58s8.48 2.3 16.66.88c6.92-1.19 7.89-2.44 7.89-2.44l2.15-9.02s-9.58 5.47-24.47 2.52l7.77 3.18h-9.92z'
            id='prefix__el0pglqaegk4pe'
            className='prefix__animable'
            style={{
              transformOrigin: '190.35px 441.961px'
            }}
          />
        </g>
        <path
          d='M243.19 379.84a92.44 92.44 0 00-12.34-18.72v-.07a30.65 30.65 0 008 .59 45.71 45.71 0 01-11.72-12.83c-4.09-15.09-8.47-30.25-8.56-45.67l-91.75-.71a283 283 0 01-12.68 54c-6.33 19-13.48 41.61-.85 60.22a33.82 33.82 0 008.54 8.5 124 124 0 0012.62 11.63q-1.16-2.93-2.29-5.85l1.57.7A62.3 62.3 0 00165 445l-1.7-4.07c13.14 2.28 26.49 1.59 38.42-4 22.27-10.5 34-22.93 36.76-39.23 1.41-8.26-.19-15.68-2.67-23.09z'
          style={{
            transformOrigin: '174.75px 373.715px'
          }}
          id='prefix__ellym0vjjemi'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M212.91 384.37h-.37c-.24 0-.6-.1-1-.21a28.48 28.48 0 01-3.81-1.14 32 32 0 01-11.28-7.26l.42-.28c.44 1.13.9 2.3 1.36 3.5.84 2.18 1.66 4.28 2.41 6.22l.57 1.48-1-1.23c-2.86-3.55-5.34-6.62-7.16-8.89l-2-2.59a7.85 7.85 0 01-.69-1 5.43 5.43 0 01.82.86l2.15 2.51 7.31 8.76-.43.25c-.75-1.94-1.57-4-2.42-6.21-.47-1.2-.92-2.38-1.36-3.5l-.44-1.14.86.87a33.52 33.52 0 0011 7.3 38 38 0 003.73 1.29 12.54 12.54 0 011.33.41z'
          style={{
            transformOrigin: '201.635px 379.825px'
          }}
          id='prefix__elsvks8ug2dqq'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M133.65 366.22a9 9 0 01-1-2.38 26.16 26.16 0 01-1.25-6.14 26.42 26.42 0 01.23-6.26 9.17 9.17 0 01.63-2.52 21.29 21.29 0 01-.26 2.56 33.09 33.09 0 00-.08 6.17 34 34 0 001.08 6.08 22 22 0 01.65 2.49z'
          style={{
            transformOrigin: '132.48px 357.57px'
          }}
          id='prefix__elbzr2ph1dpun'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M136.72 358.4a5.35 5.35 0 01-.63-1.81 20.51 20.51 0 01-.58-4.57 21.17 21.17 0 01.44-4.58 5.86 5.86 0 01.58-1.82 62.29 62.29 0 00-.5 6.4 61 61 0 00.69 6.38z'
          style={{
            transformOrigin: '136.114px 352.01px'
          }}
          id='prefix__el6f1r2lrezzv'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M169.31 417.13a2.16 2.16 0 01-.55-.1c-.36-.09-.89-.2-1.56-.4a47 47 0 01-5.59-2 52.42 52.42 0 01-16.3-10.92l.39-.34c.21.28.42.59.64.9a34.71 34.71 0 014.48 9.18l.19.6-.54-.32a27.44 27.44 0 01-9.59-9.34 26.63 26.63 0 01-1.77-3.44c-.17-.42-.28-.75-.36-1a1.3 1.3 0 01-.1-.35 10.2 10.2 0 01.61 1.24 30.29 30.29 0 001.87 3.35 28 28 0 009.58 9.08l-.35.29a34.86 34.86 0 00-4.45-9l-.62-.89.39-.34a54.4 54.4 0 0016.07 11 57 57 0 005.51 2.12c.66.23 1.18.37 1.52.49a2.75 2.75 0 01.53.19z'
          style={{
            transformOrigin: '153.98px 408.365px'
          }}
          id='prefix__elv85n6nihpim'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M122.35 324.06a1.08 1.08 0 010 .24v.68c-.05.61-.16 1.49-.31 2.63-.32 2.27-.92 5.55-1.89 9.53s-2.34 8.69-4 13.86-3.49 10.83-5.18 16.85a125.79 125.79 0 00-3.76 17.15 59.35 59.35 0 00-.23 14.31 40.89 40.89 0 002.19 9.4c.42 1.06.75 1.89 1 2.43l.27.63a.77.77 0 01.08.22s0-.06-.13-.19-.19-.37-.32-.61c-.29-.53-.65-1.35-1.09-2.41a38.81 38.81 0 01-2.38-9.43 58.29 58.29 0 01.13-14.42 122 122 0 013.69-17.27c1.71-6 3.59-11.68 5.23-16.85s3.06-9.83 4.07-13.79 1.67-7.22 2-9.48c.18-1.13.32-2 .4-2.61l.11-.68a.79.79 0 01.12-.19z'
          style={{
            transformOrigin: '114.286px 368.025px'
          }}
          id='prefix__elh6ks6w8ac3'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M126.25 421.74a5.94 5.94 0 01-.65-.54c-.39-.37-1-.87-1.76-1.64a35.87 35.87 0 01-5.38-7 46 46 0 01-5-12.15 58.69 58.69 0 01-1.75-16 86.58 86.58 0 012.06-15.95c1.06-4.83 2.3-9.11 3.41-12.68s2.09-6.42 2.77-8.39c.34-.95.6-1.71.8-2.27a5.05 5.05 0 01.32-.78 6.27 6.27 0 01-.22.82c-.17.56-.41 1.33-.71 2.29-.62 2-1.54 4.87-2.61 8.44s-2.26 7.85-3.29 12.67a88.58 88.58 0 00-2 15.86 59.8 59.8 0 001.76 15.85 46.61 46.61 0 004.83 12.08 37.85 37.85 0 005.21 7.07c.68.79 1.31 1.3 1.68 1.71a4.37 4.37 0 01.53.61z'
          style={{
            transformOrigin: '118.968px 383.04px'
          }}
          id='prefix__el2gpnm62ekcs'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M135.63 424.81a35.05 35.05 0 01-4.83-3.73 34.56 34.56 0 01-4.49-4.13 36.06 36.06 0 014.82 3.73 36 36 0 014.5 4.13z'
          style={{
            transformOrigin: '130.97px 420.88px'
          }}
          id='prefix__el7b2i06ioxyp'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M99.37 144.06c-.75.19-19.23 8.73-15.75 33.24 3 21.47 15.75 22 15.75 22l23.68-20.7s-17.19-1.83-21.63-14.36c-4.51-12.75-2.05-20.18-2.05-20.18z'
          style={{
            transformOrigin: '103.117px 171.68px'
          }}
          id='prefix__el858k1zum6s8'
          className='prefix__animable'
          fill='#455a64'
        />
        <path
          d='M109.72 173.77c0 .1-1.71.06-4.36.7A21.68 21.68 0 0090 187.94c-1 2.55-1.16 4.26-1.26 4.24a4.9 4.9 0 01.1-1.2 19.09 19.09 0 01.81-3.17 21 21 0 0115.59-13.7 17.73 17.73 0 013.25-.39 4.48 4.48 0 011.23.05z'
          style={{
            transformOrigin: '99.228px 182.94px'
          }}
          id='prefix__elesvqlt5xve'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M102.67 166c0 .1-1.39-.16-3.6 0a17.08 17.08 0 00-13.84 9c-1 2-1.37 3.31-1.47 3.28a3.15 3.15 0 01.19-1 13.74 13.74 0 011-2.49 16.49 16.49 0 0114.14-9.21 14.1 14.1 0 012.66.13 3.17 3.17 0 01.92.29z'
          style={{
            transformOrigin: '93.215px 171.923px'
          }}
          id='prefix__el3xpvm2tl5v'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M84.62 161.65s0-.25.22-.67a9.11 9.11 0 011-1.66 10.54 10.54 0 015.13-3.67 8.82 8.82 0 016.28.34 7.63 7.63 0 011.67 1c.36.27.53.46.51.48s-.83-.56-2.32-1.1a9.08 9.08 0 00-6-.19 11.06 11.06 0 00-5 3.41c-1 1.18-1.4 2.1-1.49 2.06z'
          style={{
            transformOrigin: '92.0258px 158.44px'
          }}
          id='prefix__elleygpp2y1xf'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M94.76 148.34s-.48.53-1.35 1.47a33.81 33.81 0 00-3.32 4.26 32.57 32.57 0 00-1.6 33.82 34.71 34.71 0 002.91 4.55c.77 1 1.23 1.56 1.2 1.59a2.26 2.26 0 01-.38-.36c-.22-.25-.58-.6-1-1.11a29.21 29.21 0 01-3.07-4.5 32.07 32.07 0 011.61-34.2 29.85 29.85 0 013.49-4.19c.44-.47.83-.78 1.08-1a1.79 1.79 0 01.43-.33z'
          style={{
            transformOrigin: '89.3669px 171.185px'
          }}
          id='prefix__eler74g8csh7'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M239.61 146.17c.74.18 19.22 8.73 15.74 33.24-3 21.47-15.74 22-15.74 22l-23.68-20.69s17.18-1.83 21.63-14.36c4.5-12.76 2.05-20.19 2.05-20.19z'
          style={{
            transformOrigin: '235.858px 173.79px'
          }}
          id='prefix__ello97lss8h7q'
          className='prefix__animable'
          fill='#455a64'
        />
        <path
          d='M229.25 175.87a5.16 5.16 0 011.2-.05 18.87 18.87 0 013.25.4 20.93 20.93 0 0115.59 13.7 19.09 19.09 0 01.81 3.17 4.62 4.62 0 01.11 1.19c-.11 0-.29-1.68-1.27-4.24a21.65 21.65 0 00-15.32-13.46c-2.62-.64-4.38-.58-4.37-.71z'
          style={{
            transformOrigin: '239.732px 185.043px'
          }}
          id='prefix__elrfqpg6by8d'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M236.79 168.19a3.42 3.42 0 01.94-.22 13.43 13.43 0 012.61-.14 15.55 15.55 0 018.13 2.73 16.71 16.71 0 015.63 6.44 14.31 14.31 0 01.93 2.45 3.07 3.07 0 01.18.94c-.09 0-.42-1.3-1.44-3.22a17.73 17.73 0 00-5.59-6.22 16.25 16.25 0 00-7.86-2.79c-2.17-.16-3.52.13-3.53.03z'
          style={{
            transformOrigin: '246px 174.104px'
          }}
          id='prefix__elhngt3womqpv'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M254.35 163.76c-.08 0-.48-.88-1.48-2.11a11 11 0 00-5-3.4 9 9 0 00-6 .19c-1.49.54-2.26 1.18-2.32 1.1s.15-.21.51-.49a8.06 8.06 0 011.67-1 8.89 8.89 0 016.29-.34 10.56 10.56 0 015.12 3.68 8.78 8.78 0 011 1.66 1.74 1.74 0 01.21.71z'
          style={{
            transformOrigin: '246.945px 160.526px'
          }}
          id='prefix__elk3auia3bc4n'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M241.28 148.39a5.77 5.77 0 01.41 1.14 9.72 9.72 0 01.36 1.38 18.15 18.15 0 01.34 1.87 21.28 21.28 0 01.2 2.28 26 26 0 010 2.64 29.12 29.12 0 01-1 5.94 28.61 28.61 0 01-2.36 5.56c-.48.79-.91 1.56-1.41 2.22a19.39 19.39 0 01-1.42 1.81 16.21 16.21 0 01-1.29 1.38 8.57 8.57 0 01-1.05 1 5.09 5.09 0 01-1 .74c-.07-.08 1.29-1.17 3-3.32a21 21 0 001.34-1.82c.48-.65.89-1.42 1.36-2.2a31 31 0 002.27-5.48 30.26 30.26 0 001.06-5.83c0-.91.11-1.77.07-2.59a21.89 21.89 0 00-.14-2.25c-.3-2.79-.85-4.44-.74-4.47z'
          style={{
            transformOrigin: '237.84px 162.37px'
          }}
          id='prefix__el2o4t3iaafp'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M154.86 160.31s-3.12.89-1.78-11.61l10.71 11.62s-4.35-7.16-5.52-13.41c0 0 13.54 12.53 18.24 13.41s36.86 2.67 55.61 18.75 27.26 31.66 29.3 48.52c3.32 27.33-1.61 50.62-14.56 66.25l-.44-4.46s-13.85 20.09-34.84 25.9-83.07 0-83.07 0-34.83-9.38-46.89-36.62-5.36-31.26-5.36-31.26l-5.19 4.9s-1.36-39.22 33.77-67c27.69-21.86 50.02-24.99 50.02-24.99z'
          style={{
            transformOrigin: '166.743px 232.386px'
          }}
          id='prefix__elek778g4z8ct'
          className='prefix__animable'
          fill='#ff725e'
        />
        <path
          d='M222.42 310.82s-.07.06-.23.14l-.71.37c-.63.32-1.54.8-2.76 1.38-2.42 1.16-6 2.78-10.55 4.49a93.75 93.75 0 01-16.7 4.62 63.89 63.89 0 01-10.36 1 51.23 51.23 0 01-11.28-1.06l.2-.46 8.67 6 1.05.73-1.26-.27-2.32-.49-20.64-4.43.23-.42 4.84 5.1.56.58-.78-.19a122.7 122.7 0 01-26.66-9.88c-3.1-1.58-5.47-2.95-7.08-3.92-.81-.48-1.41-.87-1.82-1.13l-.46-.31c-.11-.07-.16-.1-.15-.11l.17.08.48.27 1.86 1.07c1.62.93 4 2.26 7.13 3.8a126.81 126.81 0 0026.64 9.67l-.23.39-4.85-5.08-.56-.58.79.17 20.64 4.41 2.32.5-.2.46-8.66-6.05-1-.73 1.24.27a51.35 51.35 0 0011.16 1.07 63.48 63.48 0 0010.3-.94 96.31 96.31 0 0016.65-4.49c4.58-1.66 8.15-3.24 10.6-4.34l2.79-1.3.73-.33z'
          style={{
            transformOrigin: '173.314px 319.46px'
          }}
          id='prefix__elvmp66ovhtre'
          className='prefix__animable'
          fill='#263238'
        />
        <g
          style={{
            transformOrigin: '173.3px 321.802px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__el6kpr59ecedm'
        >
          <path
            d='M222.42 310.82c-16.86 8.45-35.95 14.3-52.53 10.68l8.66 6.05-23-4.92c1.62 1.7 3.24 3.39 4.85 5.09a124.22 124.22 0 01-36.22-15.12s18.26 19.08 39.4 20.1c34.88 1.67 58.84-21.88 58.84-21.88z'
            id='prefix__el2pqk8lwrpef'
            className='prefix__animable'
            style={{
              transformOrigin: '173.3px 321.802px'
            }}
          />
        </g>
        <path
          d='M105.75 283.94a2.94 2.94 0 01-.62-.35c-.39-.25-1-.59-1.71-1.11a40.46 40.46 0 01-5.75-4.86A44.6 44.6 0 0185.83 256l.51-.06v.37c.15 5.36.3 10.43.44 14.94v1.2l-.48-1.1a48.32 48.32 0 01-3.85-14.3 41.73 41.73 0 01-.26-4.2v-1.12a1.88 1.88 0 010-.38 1.65 1.65 0 010 .38q0 .43.06 1.11c0 1 .15 2.39.39 4.18a50.19 50.19 0 004 14.14l-.45.1-.5-14.93V256l.52-.06a44.64 44.64 0 004.93 12.6 45.73 45.73 0 0012.27 13.78c.69.55 1.28.91 1.65 1.2a4 4 0 01.69.42z'
          style={{
            transformOrigin: '93.9652px 267.645px'
          }}
          id='prefix__elfyfbiii9x3c'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M244.07 259.17s.09-.17.31-.46l1-1.27c.39-.57.79-1.32 1.3-2.16l.66-1.42.36-.77c.1-.28.21-.56.31-.85 1.81-4.6 2.9-11.45 2.84-19 0-1.07 0-2.13-.11-3.15l-.13-2 .64 1.9c.66 2 1.29 3.86 1.89 5.64h-.46a19.66 19.66 0 001.24-7.14 20.37 20.37 0 00-.88-5.65 27.76 27.76 0 00-2-4.69s.09.09.22.28.33.46.55.85a18.2 18.2 0 011.56 3.47 19.71 19.71 0 011 5.73 19.49 19.49 0 01-1.19 7.32l-.24.65-.22-.66-1.92-5.63.5-.1c.07 1.05.1 2.1.11 3.18.06 7.6-1.09 14.51-3 19.13-.12.29-.23.58-.34.85l-.38.78-.71 1.42c-.54.84-1 1.58-1.39 2.13l-1 1.22c-.38.28-.51.42-.52.4z'
          style={{
            transformOrigin: '249.224px 238.661px'
          }}
          id='prefix__eltsfec4ukbmd'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M199.8 184.85a3.75 3.75 0 01-.7 0c-.45 0-1.13 0-2-.06a49.55 49.55 0 01-7.28-.95 54.49 54.49 0 01-22-9.94l.34-.39.17.18c3.28 3.33 6.4 6.51 9.24 9.41l.55.56-.76-.16a24.13 24.13 0 01-8-3.15 24.58 24.58 0 01-5.23-4.28 23.21 23.21 0 01-2.67-3.5c-.27-.43-.44-.79-.57-1s-.19-.36-.17-.36a14.33 14.33 0 01.87 1.3 27.18 27.18 0 002.76 3.38 24.5 24.5 0 005.22 4.14 24.16 24.16 0 007.91 3l-.21.41-9.27-9.37-.17-.17.34-.39a55.81 55.81 0 0021.73 10 58.85 58.85 0 007.22 1.11c.86.1 1.53.11 2 .16a3 3 0 01.68.07z'
          style={{
            transformOrigin: '180.258px 178.038px'
          }}
          id='prefix__elfc58ukqidub'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M133.71 171.91a.86.86 0 01-.24.11l-.71.26c-.62.23-1.57.52-2.74 1a87.47 87.47 0 00-55.91 67.57c-.24 1.24-.34 2.21-.45 2.87 0 .3-.09.55-.13.75a.83.83 0 01-.06.25v-.26c0-.17 0-.45.07-.75.09-.66.15-1.65.36-2.9a80.32 80.32 0 012.26-10.48 85.12 85.12 0 015.74-14.83 84.25 84.25 0 0124.67-29.81 85.39 85.39 0 0113.49-8.42 80.53 80.53 0 019.88-4.18c1.19-.44 2.14-.69 2.78-.89l.72-.21a.82.82 0 01.27-.08z'
          style={{
            transformOrigin: '103.59px 208.315px'
          }}
          id='prefix__elbt9x7y9vtdp'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M88.41 281.37a7.81 7.81 0 01-1-1c-.32-.33-.69-.75-1.1-1.26s-.9-1.07-1.36-1.75a39.46 39.46 0 01-3.05-4.92 43.91 43.91 0 01-2.74-6.53 44.63 44.63 0 01-1.6-6.91 40.13 40.13 0 01-.37-5.77c0-.82 0-1.57.09-2.22a16.09 16.09 0 01.16-1.67 7.71 7.71 0 01.24-1.4 7 7 0 010 1.42c0 .46-.07 1-.07 1.67v2.2a44 44 0 00.48 5.7 48.79 48.79 0 001.59 6.82 47 47 0 002.67 6.47 42.06 42.06 0 002.93 4.92c.44.69.91 1.27 1.29 1.79s.73 1 1 1.31a7.6 7.6 0 01.84 1.13z'
          style={{
            transformOrigin: '82.7993px 264.655px'
          }}
          id='prefix__elmvtqqcbxjp'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M96.68 290.75c-.05.13-1-.09-2-.79s-1.56-1.54-1.45-1.62.8.54 1.75 1.19 1.75 1.07 1.7 1.22z'
          style={{
            transformOrigin: '94.9493px 289.559px'
          }}
          id='prefix__ellmljsbn6b5s'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M157.51 161.88a11.53 11.53 0 01-4-10.06 26.65 26.65 0 001.24 5.34 26 26 0 002.76 4.72z'
          style={{
            transformOrigin: '155.475px 156.85px'
          }}
          id='prefix__elbs8klp4xb4k'
          className='prefix__animable'
          fill='#fafafa'
        />
        <g
          style={{
            transformOrigin: '165.01px 266.168px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elluklf256m6i'
        >
          <path
            d='M148.44 272.71c.6-1 1.21-2 1.82-3a1.87 1.87 0 00.45 1.65 5 5 0 001.42 1.05c1 .56 2.32 1 3.34.46a2.9 2.9 0 001-3.14c-.19-.92-.61-1.79-.86-2.7a3.54 3.54 0 01.18-2.75 4.4 4.4 0 012.74-1.59 34.1 34.1 0 0111.92-1.27 21.28 21.28 0 0111.13 4.17c-.27-1.43-1.54-2.43-2.79-3.18a22.34 22.34 0 00-17.35-2.41 19.85 19.85 0 00-12.94 11.64'
            id='prefix__el4b1g6zko0z4'
            className='prefix__animable'
            style={{
              transformOrigin: '165.01px 266.168px'
            }}
          />
        </g>
        <path
          d='M151.15 267.41a8.05 8.05 0 002.62 4.36 1.68 1.68 0 001.74.5c.65-.33.67-1.25.55-2a16.35 16.35 0 00-2.28-5.95'
          style={{
            transformOrigin: '153.634px 268.33px'
          }}
          id='prefix__eldc6a97tbbv'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M184.75 267.41a8 8 0 01-2.61 4.36 1.7 1.7 0 01-1.75.5c-.65-.33-.67-1.25-.55-2a16.19 16.19 0 012.29-5.95'
          style={{
            transformOrigin: '182.266px 268.33px'
          }}
          id='prefix__el8hzm64kg8gs'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M189.43 271.91c-.32.24-2.13-2-5.68-4.85A31.68 31.68 0 00177 263a25.74 25.74 0 00-9.21-2.18 21.49 21.49 0 00-9.22 1.54 18.06 18.06 0 00-6.27 4.36c-3 3.26-3.54 6.22-3.95 6.11-.16 0-.25-.78 0-2.12a13.68 13.68 0 012.5-5.18 18.15 18.15 0 016.71-5.36 22 22 0 0110.32-1.95 25.55 25.55 0 0110.1 2.6 27.62 27.62 0 016.92 4.85c3.5 3.33 4.79 6.07 4.53 6.24z'
          style={{
            transformOrigin: '168.828px 265.51px'
          }}
          id='prefix__elauv1kkzvj4r'
          className='prefix__animable'
          fill='#263238'
        />
        <g
          style={{
            transformOrigin: '166.463px 286.669px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elkt3nk5viinl'
        >
          <path
            d='M176.09 284.88c.16.3-.69 1.14-2.38 2a18.55 18.55 0 01-14.35.57c-1.75-.7-2.66-1.47-2.53-1.79.29-.69 4.46.6 9.66.35s9.25-1.8 9.6-1.13z'
            id='prefix__elnbtb2qygd'
            className='prefix__animable'
            style={{
              transformOrigin: '166.463px 286.669px'
            }}
          />
        </g>
        <path
          d='M129.83 211.21a121.09 121.09 0 0076.8.67s-4.93 35.07-38.34 37-38.46-37.67-38.46-37.67z'
          style={{
            transformOrigin: '168.23px 230.079px'
          }}
          id='prefix__el3xjij5f160d'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M155.29 217.21a40.31 40.31 0 003.88 24 14.8 14.8 0 003.92 5.43 6.56 6.56 0 006.33 1.22c2-.83 3.28-2.86 4.2-4.85 4-8.69 4.42-15.78 4.84-25.35-7.34 1.89-15.1 1.54-23.17-.45z'
          style={{
            transformOrigin: '166.66px 232.705px'
          }}
          id='prefix__elm2vmq2fgbz'
          className='prefix__animable'
          fill='#263238'
        />
        <g
          style={{
            transformOrigin: '166.705px 239.435px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__ela3rh2nqwgy7'
        >
          <path
            d='M201.67 230.44c.13.07-.29 1.23-1.4 3.15a33.5 33.5 0 01-6 7.3 41.47 41.47 0 01-5.07 4.11 42.81 42.81 0 01-6.64 3.56 37.85 37.85 0 01-33.51-1.59 43.24 43.24 0 01-6.27-4.18 41.11 41.11 0 01-4.71-4.54 33.17 33.17 0 01-5.22-7.83c-.93-2-1.24-3.21-1.1-3.27s.81 1 2 2.77a43.58 43.58 0 005.69 7.07 44.65 44.65 0 004.69 4.1 43.69 43.69 0 006.06 3.75 37.38 37.38 0 0031.48 1.5 44.74 44.74 0 006.39-3.16 45.65 45.65 0 005.06-3.64 44.36 44.36 0 006.33-6.49c1.32-1.73 2.04-2.71 2.22-2.61z'
            id='prefix__elw5kkaxebqwc'
            className='prefix__animable'
            style={{
              transformOrigin: '166.705px 239.435px'
            }}
          />
        </g>
        <g
          style={{
            transformOrigin: '168.279px 209.766px'
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elluypddeamsr'
        >
          <path
            d='M199.1 206a4.08 4.08 0 00-3 .65 52.58 52.58 0 01-29.76 5.1c-10.13-1.26-20.55-4.8-28.29-11.46-2.11 1.12-2.64 4.08-1.73 6.28s2.89 3.75 4.9 5a50.29 50.29 0 0023.17 7.5 76.05 76.05 0 0024.39-2.31c3.92-1 8-2.31 10.59-5.43a5.08 5.08 0 001.29-2.84 2.51 2.51 0 00-1.56-2.49z'
            id='prefix__el2tnkvjixkcx'
            className='prefix__animable'
            style={{
              transformOrigin: '168.279px 209.766px'
            }}
          />
        </g>
        <path
          d='M197.79 205.77c.38.53-2.22 3.4-7.75 6.21a41.74 41.74 0 01-10.25 3.56 44.32 44.32 0 01-26.33-2.54 42.37 42.37 0 01-9.4-5.43c-4.89-3.81-6.9-7.13-6.42-7.57s3.43 1.74 8.44 4.49a52.12 52.12 0 0042.56 4.07c5.45-1.73 8.71-3.43 9.15-2.79z'
          style={{
            transformOrigin: '167.699px 208.214px'
          }}
          id='prefix__el0o6hur7j7qsg'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M162.64 241.07s-.23-.2-.56-.63a16.74 16.74 0 01-1.26-1.9 24.2 24.2 0 01-2.73-7 24.52 24.52 0 01-.48-7.51 17.94 17.94 0 01.35-2.26c.12-.52.2-.81.24-.8s-.13 1.18-.22 3.09a27.78 27.78 0 003.16 14.31c.86 1.68 1.57 2.63 1.5 2.7z'
          style={{
            transformOrigin: '160.078px 231.02px'
          }}
          id='prefix__ela6gbxas8uy6'
          className='prefix__animable'
          fill='#fafafa'
        />
        <path
          d='M214.57 209.81a16 16 0 01-3.3 1.47c-1.09.43-2.42.95-4 1.44s-3.35 1.14-5.37 1.64-4.21 1.16-6.62 1.63c-1.2.25-2.43.55-3.72.77l-4 .66a126.61 126.61 0 01-17.89 1.36 128.6 128.6 0 01-17.89-1.3c-2.72-.46-5.32-.85-7.7-1.42-1.2-.27-2.36-.51-3.46-.78l-3.16-.86c-2-.52-3.8-1.15-5.37-1.66-.79-.27-1.52-.5-2.19-.74l-1.8-.72a15.58 15.58 0 01-3.29-1.49c.09-.26 4.91 1.55 12.93 3.54l3.15.75c1.1.25 2.26.45 3.45.69 2.37.52 4.94.84 7.64 1.27a144.23 144.23 0 0017.69 1.16 137.88 137.88 0 0017.71-1.22l3.94-.58c1.28-.19 2.51-.47 3.7-.69 2.39-.41 4.59-1 6.6-1.43s3.81-1 5.4-1.41 2.94-.83 4-1.17a15.86 15.86 0 013.55-.91z'
          style={{
            transformOrigin: '169.69px 214.283px'
          }}
          id='prefix__eldbvjnvhr24q'
          className='prefix__animable'
          fill='#263238'
        />
      </g>
      <g
        id='prefix__freepik--Floor--inject-46'
        className='prefix__animable'
        style={{
          transformOrigin: '249.225px 480.07px',
          animation:
            '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) lightSpeedLeft',
          animationDelay: '0s'
        }}
      >
        <path
          d='M477 480.07c0 .14-102 .26-227.77.26s-227.78-.12-227.78-.26 102-.26 227.78-.26 227.77.12 227.77.26z'
          style={{
            transformOrigin: '249.225px 480.07px'
          }}
          id='prefix__el8ah7q8ea84a'
          className='prefix__animable'
          fill='#263238'
        />
      </g>
      <g
        id='prefix__freepik--Lightning--inject-46'
        className='prefix__animable'
        style={{
          transformOrigin: '214.57px 131.43px',
          animation:
            '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) lightSpeedLeft',
          animationDelay: '0s'
        }}
      >
        <path
          style={{
            transformOrigin: '202.775px 129.825px'
          }}
          id='prefix__elh951d0u9wqc'
          className='prefix__animable'
          fill='#ff725e'
          d='M198.7 153.57l-5.42-24.39 12.92-1.33 4.2-21.77 1.87 26.89-13.12 1.31-.45 19.29z'
        />
        <path
          style={{
            transformOrigin: '220.94px 145.1px'
          }}
          id='prefix__elb0ybg5uiaka'
          className='prefix__animable'
          fill='#ff725e'
          d='M206.02 156.78l8.36-17.46 8.52 5.37 12.96-11.27-11.71 17.29-8.63-5.48-9.5 11.55z'
        />
        <path
          style={{
            transformOrigin: '202.775px 129.825px'
          }}
          className='prefix__animable'
          fill='#fff'
          opacity={0.6}
          d='M198.7 153.57l-5.42-24.39 12.92-1.33 4.2-21.77 1.87 26.89-13.12 1.31-.45 19.29z'
          id='prefix__el93k0w0f32tj'
        />
        <path
          style={{
            transformOrigin: '220.94px 145.1px'
          }}
          className='prefix__animable'
          fill='#fff'
          opacity={0.6}
          d='M206.02 156.78l8.36-17.46 8.52 5.37 12.96-11.27-11.71 17.29-8.63-5.48-9.5 11.55z'
          id='prefix__elrs3p1ccktnd'
        />
      </g>
      <defs>
        <filter id='prefix__active' height='200%'>
          <feFlood floodColor='#32DFEC' floodOpacity={1} result='PINK' />
          <feComposite in='PINK' in2='DILATED' operator='in' result='OUTLINE' />
          <feMerge>
            <feMergeNode in='OUTLINE' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
        <filter id='prefix__hover' height='200%'>
          <feFlood floodColor='red' floodOpacity={0.5} result='PINK' />
          <feComposite in='PINK' in2='DILATED' operator='in' result='OUTLINE' />
          <feMerge>
            <feMergeNode in='OUTLINE' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
          <feColorMatrix values='0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
