import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo/Seo'
import NonExistant from '../svg/NonExistant'
const NotFoundPage = () => {
  return (
    <Layout
      title='TOYREADS - 404 Page Not Found'
      subtitle='page does not exist!'
    >
      <SEO title='TOYREADS - 404 Page Not Found' desc='page does not exist!' />
      <div className='container'>
        <div className='columns is-vcentered'>
          <div className='column has-text-centered'>
            <h1 className='is-size-1'>TOYREADS</h1>
            <h5 className='is-size-5 is-italic is-family-monospace'>
              bring me all the fun in the world!
            </h5>
          </div>
          <div className='column'>
            <NonExistant />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
